
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import Table from "@/app/ui/components/table/index.vue";
import {
  checkRolePermission,
  dateToDateString,
  firstCapitalize,
  formatDate
} from "@/app/infrastructures/misc/Utils";
import { ANNOUNCEMENT } from "@/app/infrastructures/misc/RolePermission";
import { AnnouncementController } from "@/app/ui/controllers/AnnouncementController";
import { AnnouncementData } from "@/domain/entities/Announcement";
import ModalShowImage from "@/app/ui/components/modal-show-image/index.vue";
import "quill/dist/quill.core.css";

@Options({
  components: {
    DetailLayout,
    Table,
    ModalShowImage
  },
  computed: {
    isEditAble() {
      return (
        checkRolePermission(ANNOUNCEMENT.EDIT) &&
        this.detailData.status.toLowerCase() === "draft"
      );
    }
  }
})
export default class Detail extends Vue {
  // misc variable
  modelDialog = false;
  get id(): number {
    return Number(this.$route.params.id);
  }

  // Get Data
  created() {
    this.getDetail();
  }

  getDetail() {
    AnnouncementController._onGetDetail(this.id);
  }

  // Detail Data
  get detailData(): AnnouncementData {
    return AnnouncementController.announcementDetail;
  }

  // Loading
  get isLoading() {
    return AnnouncementController.isLoading;
  }

  get styleStatusBaseRates() {
    if (this.detailData.status.toLowerCase() === "send")
      return "bg-green-lp-300 text-green-lp-200";
    else return "bg-gray-lp-200 text-gray-lp-300";
  }

  // route navigation
  goBack() {
    this.$router.push("/admin/announcement");
  }
  goEdit() {
    this.$router.push(`/admin/announcement/${this.$route.params.id}/edit`);
  }

  // Delete
  onDelete() {
    AnnouncementController._onDelete(this.id);
  }
  get isConfirmDelete() {
    return AnnouncementController.openDelete;
  }
  onOpenDelete(value: boolean) {
    AnnouncementController.setOpenDelete(value);
  }

  // Format Periode
  formatPeriode(start: string, end: string) {
    return `${dateToDateString(start)} - ${dateToDateString(end)}`;
  }

  // Format Date
  formatDateToString(date: string) {
    return formatDate(date);
  }

  firstCapitalizeString(text: string) {
    return firstCapitalize(text);
  }

  // Handle Error
  get isError() {
    return AnnouncementController.isError;
  }
  get errorCause() {
    return AnnouncementController.isErrorCause;
  }

  // Show image
  isShow = false;
  onOpenShowImage() {
    this.isShow = true;
  }
  onCloseShowImage() {
    this.isShow = false;
  }
}
